<template>
  <div style="overflow: hidden; min-width: 50vw;">
    <v-row align="center">
      <v-col cols="12" sm="1">
        <v-btn
            small
            outlined
            color="white"
            @click="back"
        >{{ $t('labelBack') }}</v-btn>
      </v-col>
      <v-col cols="12" sm="10" align="center">
        <span class="white--text font-weight-bold pt-0 canary-title">{{ $t('titleNewCase') }}</span><br/>
        <span v-if="$i18n.locale=='de'" class="red--text font-weight-black">
          Bitte geben Sie personenbezogene Daten nur dann weiter, wenn die grenzüberschreitende Weiterleitung zulässig ist.<br/>
          Anonymisieren Sie bitte im Zweifelsfall Ihre Angaben. Wir melden uns bei Ihnen über diese<br/>
          Hinweisgeber-Plattform, falls weitere Informationen erforderlich sein sollten.
        </span>
        <span v-else class="red--text font-weight-black">
          Please disclose client-identifying information only if case-cross border sharing is permitted.<br/>
          In case of doubt, please anonymize. We will get back to you through this
          Whistleblowing Platform in case further information is required.
        </span>
      </v-col>
      <v-col cols="12" sm="1"></v-col>
    </v-row>

    <v-row class="pa-0 ma-0" style="height: 70vh;">
      <v-col cols="12" class="pl-1 pr-0 pt-5 ma-0 mt-1">
        <v-sheet elevation="0" tile class="pl-5 py-5 pr-0 ma-0" min-width="50vw" max-width="60vw">
          <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
          <v-sheet v-if="!loading" class="scroll" tile>
            <v-sheet
              flat
              tile
              class="pa-5"
              max-height="60vh"
              style="padding-right: 40px !important;"
            >
              <v-form ref="caseForm">
                <v-row v-if="caseType !== 'anonymous'" align="center">
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="newCase.metaData.firstName"
                      :label="$t('labelFirstName')+'*'"
                      :hint="$t('hintFirstName')"
                      :rules="requiredRules"
                      persistent-hint
                      validate-on-blur
                      :disabled="creatingCase"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="newCase.metaData.lastName"
                      :label="$t('labelLastName')+'*'"
                      :hint="$t('hintLastName')"
                      :rules="requiredRules"
                      persistent-hint
                      validate-on-blur
                      :disabled="creatingCase"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="newCase.metaData.emailAddress"
                      :label="$t('labelEmailAddress')"
                      :hint="$t('hintEmailAddress')"
                      :rules="emailRules"
                      persistent-hint
                      validate-on-blur
                      :disabled="creatingCase"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="layout !== null">
                  <template v-for="(col, index) in layout.columns">
                    <v-col cols="12" :md="getFieldWidth(col)">
                      <p class="primary--text">{{ getFieldHint(col) }}</p>
                      <component
                        v-model="newCase.metaData[layout.fields[col].name]"
                        :is="layout.fields[col].component"
                        :rules="getFieldRules(col)"
                        :outlined="layout.fields[col].outlined"
                        :no-resize="true"
                        :rows="getFieldRows(col)"
                        :items="layout.fields[col].choices"
                        item-value="en"
                        item-text="en"
                        :disabled="creatingCase"
                      >
                        <v-radio
                          v-if="layout.fields[col].component === 'v-radio-group'"
                          v-for="(choice, index) in layout.fields[col].choices"
                          :key="index"
                          :label="choice[currentLocale]"
                          :value="index"
                          :disabled="creatingCase"
                        ></v-radio>
                        <v-checkbox
                          v-if="layout.fields[col].type === 'multipleChoice'"
                          v-model="newCase.metaData[layout.fields[col].name]"
                          v-for="(choice, index) in layout.fields[col].choices"
                          class="ma-0 pa-0"
                          multiple
                          :hide-details="index !== (layout.fields[col].choices.length -1)"
                          :key="index"
                          :rules="getFieldRules(col)"
                          :label="choice[currentLocale]"
                          :value="index"
                          :disabled="creatingCase"
                        ></v-checkbox>
                      </component>
                    </v-col>
                  </template>
                </v-row>
                <v-row>
                  <v-file-input
                    v-model="files"
                    color="primary"
                    class="small-input"
                    counter
                    :label="$t('labelAttachments')"
                    multiple
                    clearable
                    :placeholder="$t('hintAttachments')"
                    prepend-icon="attachment"
                    outlined
                    :show-size="1000"
                    :rules="fileRules"
                    :disabled="creatingCase"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 5"
                        :color="fileSize(index) <= 20 && fileAllowed(index)  ? 'primary' : 'error'"
                        dark
                        label
                        small
                        close
                        close-icon="delete_outline"
                        @click:close="removeAttachment(index)"
                      >{{ text }}</v-chip>
                    </template>
                  </v-file-input>
                </v-row>
              </v-form>
              <div class="d-flex flex-row justify-center" style="position: relative;">
                <v-btn
                  small
                  style="position: absolute; left: 0"
                  outlined
                  color="primary"
                  @click="confirmBack"
                  :disabled="creatingCase"
                >{{ $t('labelBack') }}</v-btn>
                <v-btn
                  small
                  depressed
                  color="primary"
                  @click="createNewCase"
                  :disabled="creatingCase"
                  :loading="creatingCase"
                  class="justify-center"
                >{{ $t('labelCreateCase') }}</v-btn>
              </div>
            </v-sheet>
          </v-sheet>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="showCasePassword" persistent width="700px">
      <v-card class="pa-3" flat tile>
        <v-card-title class="justify-center">{{ $t('titleCaseSuccess') }}</v-card-title>
        <v-card-text>
          <span
            class="primary--text"
            v-html="$t('messageCaseSuccess', { caseNumber: currentCase._key, password: currentCase.password })"
          ></span>
        </v-card-text>
        <v-card-actions>
          <v-row class="pa-0">
            <v-col class="pa-0">
              <div class="d-flex flex-row justify-center">
                <v-btn small depressed color="primary" @click="viewCase">{{ $t('labelContinue') }}</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import {
  VDatePicker,
  VTextField,
  VTextarea,
  VSwitch,
  VRadioGroup,
  VRadio,
  VItemGroup,
  VSelect,
} from "vuetify/lib";
export default {
  name: "Setup",
  components: {
    VTextField,
    VTextarea,
    VSwitch,
    VDatePicker,
    VRadioGroup,
    VRadio,
    VItemGroup,
    VSelect,
  },
  data: () => ({
    files: [],
    meta: {},
    clientId: null,
    caseKey: null,
    caseType: null,
    message: { caseKey: "", message: "" },
    creatingCase: false,
    showCasePassword: false,

    newCase: {
      firstName: null,
      lastName: null,
      emailAddress: null,
      nickName: null,
      metaData: {},
      layout: null,
    },

    policyDialog: true,
  }),
  methods: {
    ...mapActions([
      "getClient",
      "getLayout",
      "getFaqLibrary",
      "setLoading",
      "createCase",
      "createCaseMessage",
      "createMessageAttachment",
    ]),
    async initData() {
      this.setLoading(true);

      try {
        if (!this.client._key) await this.getClient(this.clientId);

        await this.getLayout(this.client.layoutKey);

        if (!this.faqLibrary.entries) await this.getFaqLibrary();
      } catch (error) {
        this.$bus.$emit("errorMessage", "An unknown error occurred");
        this.$router.push({ path: "/" });
      } finally {
        this.setLoading(false);
      }
    },
    async createNewCase() {
      if (this.$refs.caseForm.validate() === true) {
        this.creatingCase = true;
        try {
          // Set nickName for anonymous case
          if(this.caseType === 'anonymous')
            this.newCase.metaData.nickName = 'Whistleblower';

          // Assign the layout to the case
          if (Object.keys(this.layout).length > 0)
            this.newCase.layout = this.layout;

          let dto = { clientIdentifier: this.clientId, newCase: this.newCase };
          await this.createCase(dto);
          this.caseKey = this.currentCase._key;
          await this.createMessage();
        } catch (error) {
          this.$router.push({ path: "/" });
          this.$bus.$emit("errorMessage", "An unknown error occurred");
        } finally {
          this.creatingCase = false;
        }
      } else {
        this.$nextTick(() => {
          let el = document.querySelector(".error--text:first-of-type");
          el.scrollIntoView();
        });
      }
    },
    async createMessage() {
      let dto = {
        clientIdentifier: this.clientId,
        caseKey: this.caseKey,
        message: this.message,
      };
      let message;
      try {
        let result = await this.createCaseMessage(dto);
        message = result.data.data;
      } catch(error) {
        this.$router.push({ path: "/" });
        this.$bus.$emit("errorMessage", "An unknown error occurred");
      }

      try {
        if (this.files.length > 0)
          await this.sendAttachments(message);
      } catch(error) {
        this.$bus.$emit("errorMessage", "Attachment upload blocked");
      }
      this.showCasePassword = true;
    },
    async sendAttachments(message) {
      let requests = [];

      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];

        try {
          let attachmentDTO = {
            clientIdentifier: this.clientId,
            caseKey: this.caseKey,
            messageKey: message._key,
            file: file,
          };

          requests.push(this.createMessageAttachment(attachmentDTO));
        } catch (err) {
          this.$bus.$emit("errorMessage", "An unknown error occurred");
        }
      }

      await Promise.all(requests);
    },
    viewCase() {
      let path = `/${this.clientId}/${this.currentCase._key}`;
      this.$router.push({ path: path.replace("//", "/") });
    },
    confirmBack() {
      let msg = this.$t("messageConfirmBack");
      this.$store.commit("setConfirmText", msg);
      this.$store.commit("setConfirmAction", () => {
        this.back();
      });
      this.$store.commit("setConfirmDialog", true);
    },
    back() {
      this.$router.go(-1);
    },
    removeAttachment(index) {
      this.files.splice(index, 1);
    },
    fileSize(index) {
      return this.files[index].size / 1000 / 1000;
    },
    fileAllowed(index) {
      const extension = this.files[index].name.split('.').pop();
      return this.extensionAllowed(extension);
    },
    extensionAllowed(extension) {
      const forbidden = ['zip', 'tar', 'gz', 'bz2', 'xv', 'iso', '7z', 'rar'];

      if(forbidden.includes(extension))
        return false;

      if(this.configuration.fileTypeRestriction && this.configuration.fileTypeRestriction.whiteList) {
        return this.configuration.fileTypeRestriction.whiteList.includes(extension);
      } else if(this.configuration.fileTypeRestriction && this.configuration.fileTypeRestriction.blackList) {
        return !this.configuration.fileTypeRestriction.blackList.includes(extension);
      } else {
        return true;
      }
    },
    getFieldWidth(fieldIndex) {
      let field = this.layout.fields[fieldIndex];

      if (field === null || typeof field === "undefined") return 6;

      if (field.fullWidth === true) return 12;

      return field.width;
    },
    getFieldLabel(fieldIndex) {
      let field = this.layout.fields[fieldIndex];

      if (field === null || typeof field === "undefined") return "";

      let locale = this.$i18n.locale;

      if (field.required === true) return `${field.displayNames[locale]}*`;
      else return field.displayNames[locale];
    },
    getFieldHint(fieldIndex) {
      let field = this.layout.fields[fieldIndex];

      if (field === null || typeof field === "undefined") return "";

      let locale = this.$i18n.locale;

      return field.hints[locale];
    },
    getFieldRules(fieldIndex) {
      let field = this.layout.fields[fieldIndex];

      if (field === null || typeof field === "undefined") return [];

      if (field.required === true) {
        return [(v) => !!v || this.$t("errorFieldRequired")];
      } else {
        return [];
      }
    },
    getFieldRows(fieldIndex) {
      let field = this.layout.fields[fieldIndex];

      if (field === null || typeof field === "undefined") return 0;

      return field.rows;
    },
  },
  computed: {
    ...mapGetters(["operatorName"]),
    ...mapState(["client", "configuration", "currentCase", "faqLibrary", "layout", "loading"]),
    currentLocale() {
      return this.$i18n.locale;
    },
    caseLink() {
      let path = `${this.clientId}/${this.currentCase._key}`;
      let location = window.location.origin.toString();

      if (location.endsWith("/")) location = location.slice(0, -1);

      return `${location}/${path}`;
    },
    fileRules() {
      return [
        (v) => !!(v.length < 6) || this.$t("errorTooManyFiles"),
        (v) => {
          var t = v.filter((x) => x.size / 1000 / 1000 > 20.9);

          if (t.length > 0) return this.$t("errorFileTooLarge");

          return true;
        },
        (v) => {
          var t = v.filter((x) => {
            const extension = x.name.split('.').pop();
            return !this.extensionAllowed(extension);
          })

          if(t.length > 0) return "File type not allowed";

          return true;
        }
      ];
    },
    emailRules() {
      return [
        (v) => {
          if (v && v.length > 0) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(v) || this.$t("errorInvalidEmail");
          }

          return true;
        },
      ];
    },
    requiredRules() {
      return [(v) => !!v || this.$t("errorFieldRequired")];
    },
  },
  async mounted() {
    this.clientId = this.$route.params.clientId;
    this.caseType = this.$route.params.caseType;

    await this.initData();
  },
};
</script>

<style scoped lang="scss">
.scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

.new-case-title {
  font-size: 1.25rem !important;
}

::v-deep .v-label {
  font-size: 1rem !important;
}
</style>
