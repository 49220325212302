<template>
  <div style="width: 100vw;">
    <v-navigation-drawer v-if="authenticated" permanent app dark color="#1e1e1e">
      <v-list dense>
        <div v-if="self.caseManager">
          <v-list-item to="/admin/cases">
            <v-list-item-action>
              <v-icon>folder</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Cases by Clients</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="mx-3 my-2"></v-divider>
        </div>
        <div>
          <v-list-item v-if="self.admin" to="/admin/users">
            <v-list-item-action>
              <v-icon>group</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>User Administration</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="self.admin || self.contentManager" to="/admin/clients">
            <v-list-item-action>
              <v-icon>business</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Client Administration</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="self.contentManager" to="/admin/configuration">
            <v-list-item-action>
              <v-icon>settings</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-slide-y-transition>
            <v-list-item
              to="/admin/configuration"
              exact
              style="padding-left: 30px"
              v-show="$route.path.includes('/admin/configuration')"
            >
              <v-list-item-action>
                <v-icon size="20">public</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Global</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-slide-y-transition>
          <v-slide-y-transition>
            <v-list-item
                to="/admin/configuration/security"
                exact
                style="padding-left: 30px"
                v-show="$route.path.includes('/admin/configuration')"
            >
              <v-list-item-action>
                <v-icon size="20">security</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Security</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-slide-y-transition>
          <v-slide-y-transition>
            <v-list-item
              to="/admin/configuration/languages"
              exact
              style="padding-left: 30px"
              v-show="$route.path.includes('/admin/configuration')"
            >
              <v-list-item-action>
                <v-icon size="20">language</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Language</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-slide-y-transition>
          <v-slide-y-transition>
            <v-list-item
              to="/admin/configuration/layouts"
              exact
              style="padding-left: 30px"
              v-show="$route.path.includes('/admin/configuration')"
            >
              <v-list-item-action>
                <v-icon size="20">layers</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Layouts</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-slide-y-transition>
          <v-slide-y-transition>
            <v-list-item
              to="/admin/configuration/faq"
              exact
              style="padding-left: 30px"
              v-show="$route.path.includes('/admin/configuration')"
            >
              <v-list-item-action>
                <v-icon size="20">question_answer</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>FAQ</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-slide-y-transition>
        </div>

        <v-list-item @click="initiateLogout">
          <v-list-item-action>
            <v-icon>logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <router-view></router-view>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
export default {
  name: "AdminView",
  methods: {
    ...mapActions("admin", ["getIdentity", "logout", "refreshToken"]),
    ...mapMutations("admin", ["setAuthenticated", "setToken"]),
    initiateLogout() {
      this.logout()
        .then((response) => {
          this.$bus.$emit("successMessage", "Logout succeeded");
        })
        .catch((error) => {
          this.$bus.$emit("errorMessage", "Logout failed");
        });
    },
  },
  computed: {
    ...mapState("admin", ["authenticated", "self"]),
  },
  async mounted() {
    this.$i18n.locale = 'en';
    localStorage.setItem("locale", 'en');
    const response = await this.getIdentity();

    if(response.data.data === null) {
      try {
        const response = await this.refreshToken();
        const token = response.data.data.token;
        this.setToken(token);
        this.setAuthenticated(true);

        if (this.self.caseManager)
          await this.$router.push("/admin/cases");
        else if (this.self.admin)
          await this.$router.push("/admin/clients");
        else if (this.self.contentManager)
          await this.$router.push("/admin/configuration");
        else
          await this.$router.push("/");
      } catch(e) {
        console.log(e);
      }
    }
  },
  watch: {
    // Forward authenticated users to the cases screen by default
    authenticated(newValue, oldValue) {
      if (newValue == false && this.$route.path !== "/admin/login")
        this.$router.push("/admin/login");
    },
  },
};
</script>

<style>
</style>